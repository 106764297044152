<template>
  <div>
    <section class="section1 ">
      <div class="seccionlore1 backnaranja container-fluid">
        <div class="row">
          <div class="col-9 pe-0">
            <p
                style="text-align: center; letter-spacing: -0.01em"
                class="titulo1 textonaranja"
            >
              Amigos por siempre
            </p>
            <p class="texto2 textonaranja pe-2">
              Siempre he considerado muy importante que el diseño gráfico y el
              branding hagan un buen equipo para el desarrollo de la Identidad
              Corporativa de una empresa, ya que ésta es la representación de la marca de
              un producto o servicio, la cual, abarca todos los aspectos visuales de
              la identidad de la compañía, y a su vez establece una relación
              positiva y directa con los clientes.
            </p>
          </div>
          <div class="col-3 ps-0">
            <img src="../assets/imagenes/inicio/cup_cake.png" alt="Lapiz" class="cupcake ">
          </div>
        </div>
      </div>
    </section>

    <!---------------------------contenido----------------------------->

    <div class="container-fluid contimg">
      <div>
        <img src="../assets/imagenes/ic/fisionaciones.png" class="fion" alt=""/>
      </div>
    </div>

    <div class="container-fluid contimg">
      <div>
        <img src="../assets/imagenes/ic/el_dragon_de_oro.png" class="fion" alt=""/>
      </div>
    </div>

    <div class="container-fluid contimg">
      <div>
        <img src="../assets/imagenes/ic/sivya_ruiz.png" class="fion" alt=""/>
      </div>
    </div>

    <div class="container-fluid contimg">
      <div>
        <img src="../assets/imagenes/ic/st.png" class="fion" alt=""/>
      </div>
    </div>

    <div class="container-fluid contimg">
      <div>
        <img src="../assets/imagenes/ic/quimichis.png" class="fion" alt=""/>
      </div>
    </div>

    <div class="container-fluid contimg">
      <div>
        <img src="../assets/imagenes/ic/dra_galleta.png" class="fion" alt=""/>
      </div>
    </div>

    <div class="container-fluid contimg">
      <div>
        <img src="../assets/imagenes/ic/fumitec.png" class="fion" alt=""/>
      </div>
    </div>

    <div class="container-fluid contimg">
      <div>
        <img src="../assets/imagenes/ic/conceptos_graficos.png" class="fion" alt=""/>
      </div>
    </div>

    <div class="container-fluid contimg">
      <div>
        <img src="../assets/imagenes/ic/chile_y_toro.png" class="fion" alt=""/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IdentidadCorporativa",
  mounted() {
    this.$store.commit("SET_FOOTER", [true,'backnaranja']);
  }
}
</script>

<style scoped>

</style>